import * as React from "react";
import {useState} from "react";
import {toast} from "react-toastify";
import {DialogTitle, Modal, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import {JsonForms} from "@jsonforms/react";
import {vanillaCells, vanillaRenderers} from "@jsonforms/vanilla-renderers";
import Button from "@mui/joy/Button";

const cardSchema = {
    type: "object",
    properties: {
        id: {
            type: "integer"
        },
        name: {
            type: "string"
        },
        old_names: {
            type: "array",
            items: {
                type: "string"
            }
        },
        image: {
            type: "string"
        },
        website: {
            type: "string"
        },
        ski_passes: {
            type: "array",
            items: {
                type: "string"
            }
        },
        total_lifts: {
            type: "integer"
        },
        state: {
            type: "string"
        },
        country: {
            type: "string"
        },
        region: {
            type: "string"
        },
        total_area: {
            type: "string"
        },
        green_trails: {
            type: "integer"
        },
        blue_trails: {
            type: "integer"
        },
        black_trails: {
            type: "integer"
        },
        winter_olympics: {
            type: "array",
            items: {
                type: "string"
            }
        },
        annual_visitors: {
            type: "integer"
        },
        family_friendly: {
            type: "boolean"
        },
        beginner_friendly: {
            type: "boolean"
        },
    }
};
const cardUISchema = {
    type: "VerticalLayout",
    elements: [
        {
            type: "Control",
            scope: "#/properties/id",
            options: {
                readOnly: true
            }
        },
        {
            type: "Control",
            scope: "#/properties/name"
        },
        {
            type: "Control",
            scope: "#/properties/old_names"
        },
        {
            type: "Control",
            scope: "#/properties/image"
        },
        {
            type: "Control",
            scope: "#/properties/website"
        },
        {
            type: "Control",
            scope: "#/properties/state"
        },
        {
            type: "Control",
            scope: "#/properties/country"
        },
        {
            type: "Control",
            scope: "#/properties/region"
        },
        {
            type: "Control",
            scope: "#/properties/total_area"
        },
        {
            type: "Control",
            scope: "#/properties/green_trails"
        },
        {
            type: "Control",
            scope: "#/properties/blue_trails"
        },
        {
            type: "Control",
            scope: "#/properties/black_trails"
        },
        {
            type: "Control",
            scope: "#/properties/winter_olympics"
        },
        {
            type: "Control",
            scope: "#/properties/annual_visitors"
        },
        {
            type: "Control",
            scope: "#/properties/ski_passes"
        },
        {
            type: "Control",
            scope: "#/properties/total_lifts"
        },
        {
            type: "Control",
            scope: "#/properties/family_friendly"
        },
        {
            type: "Control",
            scope: "#/properties/beginner_friendly"
        },
    ]
};

export function CardEditModal({modalOpen, setModalOpen, token, initial_card}) {
    const [updatedCard, setUpdatedCard] = useState(initial_card);

    function handleSubmit() {
        fetch(process.env.REACT_APP_BACKEND_URL + 'resorts', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(updatedCard)
        }).then(r => {
            if (r.ok) {
                toast('Successfully updated.');
            } else {
                toast(`HTTP Error ${r.status}`);
            }
        });
        setModalOpen(false);
    }

    return (<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog>
            <DialogTitle>Edit Resort Information</DialogTitle>
            <Box sx={{overflow: 'auto'}}>
                <JsonForms
                    data={updatedCard}
                    renderers={vanillaRenderers}
                    schema={cardSchema}
                    uischema={cardUISchema}
                    cells={vanillaCells}
                    onChange={({data, errors}) => setUpdatedCard(data)}
                >
                </JsonForms>
            </Box>
            <Button onClick={handleSubmit}>
                Submit
            </Button>
        </ModalDialog>
    </Modal>);
}
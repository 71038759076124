import {Box, Link} from "@mui/joy";
import Button from "@mui/joy/Button";
import TuneIcon from "@mui/icons-material/TuneRounded";
import Input from "@mui/joy/Input";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Dropdown from "@mui/joy/Dropdown";
import MenuButton from "@mui/joy/MenuButton";
import Avatar from "@mui/joy/Avatar";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Typography from "@mui/joy/Typography";
import ListDivider from "@mui/joy/ListDivider";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import WalletTwoToneIcon from "@mui/icons-material/WalletTwoTone";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import * as React from "react";

import {initializeApp} from "firebase/app";
import {GoogleAuthProvider, signInWithPopup, getAuth, onAuthStateChanged, signOut} from "@firebase/auth";
import {useEffect} from "react";
import {toast} from "react-toastify";

const firebaseConfig = {
    apiKey: "AIzaSyC9Vg6k8eaqEeYfgYcqidAooKVyET8wYmQ",
    authDomain: "slides-fyi.firebaseapp.com",
    projectId: "slides-fyi",
    storageBucket: "slides-fyi.appspot.com",
    messagingSenderId: "352395866102",
    appId: "1:352395866102:web:ba310cc315a339a8f3e1d7"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();


export function Header({searchTerm, setSearchTerm, setFilterDrawerOpen, user, setUser, currentData, setCurrentData}) {
    function handleSignIn() {
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;
                auth.currentUser.getIdToken().then((token) => {
                        setUser({
                            user: user,
                            token: token
                        });
                    }
                );
            }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(`Error code: ${errorCode}, message: ${errorMessage}, email: ${email}, credential: ${credential}`);
        });
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setUser({
                        user: user,
                        token: token
                    });
                });
            }
        });
    }, []);

    function handleLogout() {
        signOut(auth).then(()=>{
            setUser(null);
            setCurrentData('All');
        });
    }

    return <Box
        sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'space-between',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start'
            }}>
            <Button
                variant="plain"
                color="white"
                component="a"
                size="lg"
                sx={{alignSelf: 'center'}}
                onClick={() => setCurrentData('All')}
            > Slopes.fyi </Button>
            <Breadcrumbs>
                <Link onClick={() => setCurrentData("All")}> All </Link>
                {
                    currentData !== "All" ?
                        <Typography color="neutral">
                            {currentData}
                        </Typography> : null
                }
            </Breadcrumbs>
        </Box>
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1.5,
                alignItems: 'center',
            }}
        >
            <Button
                variant="plain"
                color="neutral"
                component="a"
                startDecorator={<TuneIcon/>}
                size="sm"
                sx={{alignSelf: 'center'}}
                onClick={() => {
                    setFilterDrawerOpen((prev) => {
                        return !prev;
                    });
                }}
            >Filters</Button>
            <Input
                size="sm"
                variant="outlined"
                placeholder="Search anything…"
                startDecorator={<SearchRoundedIcon color="primary"/>}
                onChange={(e) => {
                    setSearchTerm(e.target.value);
                }}
                sx={{
                    alignSelf: 'center',
                    display: {
                        xs: 'none',
                        sm: 'flex',
                    },
                }}
            >
                {searchTerm}
            </Input>
            {
                user === null ?
                    <Button onClick={handleSignIn}> Sign In </Button> :
                    <Dropdown>
                        <MenuButton
                            variant="plain"
                            size="sm"
                            sx={{maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px'}}
                        >
                            <Avatar
                                src={user.user.photoURL}
                                sx={{maxWidth: '32px', maxHeight: '32px'}}
                            />
                        </MenuButton>
                        <Menu
                            placement="bottom-end"
                            size="sm"
                            sx={{
                                zIndex: '99999',
                                p: 1,
                                gap: 1,
                                '--ListItem-radius': 'var(--joy-radius-sm)',
                            }}
                        >
                            <MenuItem>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar
                                        src={user.user.photoURL}
                                        sx={{borderRadius: '50%'}}
                                    />
                                    <Box sx={{ml: 1.5}}>
                                        <Typography level="title-sm" textColor="text.primary">
                                            {user.user.displayName}
                                        </Typography>
                                        <Typography level="body-xs" textColor="text.tertiary">
                                            {user.user.email}
                                        </Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                            <ListDivider/>
                            <MenuItem onClick={() => setCurrentData('My Favorites')}>
                                <FavoriteBorderTwoToneIcon/>
                                Liked Resorts
                            </MenuItem>
                            <MenuItem onClick={() => setCurrentData('My Collection')}>
                                <WalletTwoToneIcon/>
                                My Resorts
                            </MenuItem>
                            <ListDivider/>
                            <MenuItem onClick={handleLogout}>
                                <LogoutRoundedIcon/>
                                Log out
                            </MenuItem>
                        </Menu>
                    </Dropdown>
            }
        </Box>
    </Box>
}
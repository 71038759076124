import {Checkbox, FormLabel, List, ListItem, Select, Option, Slider} from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import {Done} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/joy/Box";

function FilterOption({item, includedPass, setIncludedPass}) {
    const selected = includedPass.includes(item);
    return (<ListItem key={item}>
        <AspectRatio
            variant={selected ? 'solid' : 'outlined'}
            color={selected ? 'primary' : 'neutral'}
            ratio={1}
            sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}
        >
            <div>{selected && <Done fontSize="md"/>}</div>
        </AspectRatio>
        <Checkbox
            size="sm"
            color="neutral"
            disableIcon
            overlay
            label={item}
            variant="outlined"
            checked={selected}
            onChange={(event) =>
                setIncludedPass((prev) => {
                    if (!event.target.checked) {
                        return prev.filter((prev_item) => prev_item !== item)
                    } else {
                        return [...prev.filter((prev_item) => prev_item !== item), item];
                    }
                })
            }
            slotProps={{
                action: {
                    sx: {
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                    },
                },
            }}
        />
    </ListItem>);
}

export function FilterSidebar({resorts, setCardFilteringFunc, isAdmin}) {
    // Network
    const allPassOptions = [
        'Icon',
        'Epic',
        'Mountain Collective Pass',
        'Powder Alliance',
        'Indy Pass',
        'New England Pass',
        'Independent Resort',
    ];
    const [includedPass, setIncludedPass] = useState([]);

    // States
    const allStates = resorts.map((item) => {
        if ((isAdmin || item.image.length !== 0) && item.state.length !== 0) {
            return item.state;
        }
        return null;
    }).filter((elem) => elem !== null);
    const allStateOptions = [...allStates.filter((item, pos) => allStates.indexOf(item) === pos), 'All'];
    const [selectedState, setSelectedState] = useState('All');
    const [totalAreaRange, setTotalAreaRange] = useState([300, 10000]);
    const [checkLift, setCheckLift] = useState(false);
    const [liftRange, setLiftRange] = useState([1, 100]);
    const [checkTrail, setCheckTrail] = useState(false);
    const [greenTrailRange, setGreenTrailRange] = useState([1, 100]);
    const [blueTrailRange, setBlueTrailRange] = useState([1, 100]);
    const [blackTrailRange, setBlackTrailRange] = useState([1, 100]);
    const [miscFilters, setMiscFilters] = useState(
        {
            hostedWinterOlympics: false,
            familyFriendly: false,
            beginnerFriendly: false,
            // noFTF: false,
            // hotelCard: false,
            // airlineCard: false,
            // cashbackCard: false,
            // nonUSBank: false,
            // restaurantCard: false,
            // groceryCard: false,
            // gasCard: false,
            // bonusForMobileWallet: false,
            // metalCard: false
        }
    );
    const filterNames = [
        'Hosted Winter Olympics',
        'Family Friendly',
        'Beginner Friendly',
        // 'No FTF',
        // 'Hotel Card',
        // 'Airline Card',
        // 'Cashback Card',
        // 'Non US Bank',
        // 'Restaurant Card',
        // 'Grocery Card',
        // 'Gas Card',
        // 'Bonus for Mobile Wallet',
        // 'Metal Card'
    ];

    // Recalculate on filter change.
    useEffect(() => {
        setCardFilteringFunc(() => (item) => {
            // Filter based on selected filters
            if (checkLift && (!item.total_lifts || item.total_lifts < liftRange[0] || item.total_lifts > liftRange[1])) {
                return false;
            }
            if (selectedState !== 'All' && item.state !== selectedState) {
                return false;
            }
            // extract total area number from string
            const total_area = parseInt(item.total_area.replace(/\D/g, ''));
            if (total_area < totalAreaRange[0] || total_area > totalAreaRange[1]) {
                return false;
            }
            if (checkTrail && (!item.green_trails || item.green_trails < greenTrailRange[0] || item.green_trails > greenTrailRange[1])) {
                return false;
            }
            if (checkTrail && (!item.blue_trails || item.blue_trails < blueTrailRange[0] || item.blue_trails > blueTrailRange[1])) {
                return false;
            }
            if (checkTrail && (!item.black_trails || item.black_trails < blackTrailRange[0] || item.black_trails > blackTrailRange[1])) {
                return false;
            }

            if (includedPass.length > 0) {
                // if any item in item.ski_passes is in includedPass, return true
                if (!item.ski_passes.some((pass) => includedPass.includes(pass))) {
                    return false;
                }
            }
            // Check if winter_olympics list is empty
            if (miscFilters.hostedWinterOlympics && !item.winter_olympics.length) {
                return false;
            }
            // Check if family_friendly 
            if (miscFilters.familyFriendly && !item.family_friendly) {
                return false;
            }
            // Check if beginner_friendly
            if (miscFilters.beginnerFriendly && !item.beginner_friendly) {
                return false;
            }

            // if (miscFilters.hotelCard && !item.reward_type.includes('hotel')) {
            //     return false;
            // }
            // if (miscFilters.airlineCard && !item.reward_type.includes('airline')) {
            //     return false;
            // }
            // if (miscFilters.cashbackCard && !item.reward_type.includes('cashback')) {
            //     return false;
            // }
            // if (miscFilters.nonUSBank && item.issuing_bank_origin_country === 'US') {
            //     return false;
            // }
            // if (miscFilters.restaurantCard && !item.reward_category.includes('dining')) {
            //     return false;
            // }
            // if (miscFilters.groceryCard && !item.reward_category.includes('grocery')) {
            //     return false;
            // }
            // if (miscFilters.gasCard && !item.reward_category.includes('gas')) {
            //     return false;
            // }
            // if (miscFilters.metalCard && !item.metal_card) {
            //     return false;
            // }
            // if (miscFilters.bonusForMobileWallet && !item.reward_category.includes('mobile wallet')) {
            //     return false;
            // }
            // Add more filter conditions based on your requirements
            return true;
        })
    }, [includedPass, selectedState, checkLift, liftRange, totalAreaRange, checkTrail, greenTrailRange, blueTrailRange, blackTrailRange, miscFilters]);

    return (<List
        orientation="vertical"
        size="sm"
        sx={{
            '--List-gap': '12px',
            '--ListItem-radius': '20px',
        }}
    >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.2,
        }}>
            <FormLabel>Ski Pass</FormLabel>
            <Box>
                {
                    allPassOptions.map((item, _) =>
                        <FilterOption item={item} includedPass={includedPass}
                                      setIncludedPass={setIncludedPass}></FilterOption>)
                }
            </Box>
            <FormLabel>State</FormLabel>
            <Select
                slotProps={{
                    listbox: {
                        sx: {
                            maxHeight: '200px',
                        },
                    },
                }}
                defaultValue="All" onChange={(e, new_value) => {
                setSelectedState(new_value);
            }}>
                {
                    allStateOptions.map((item, _) =>
                        <Option value={item}> {item}</Option>)
                }
            </Select>
            <FormLabel>
                Total Area (acres)
            </FormLabel>

            <Slider getAriaLabel={() => 'Total Area Range'}
                    min={300}
                    max={10000}
                    value={totalAreaRange}
                    onChange={(e, new_value) => {
                        setTotalAreaRange(new_value);
                    }}
                    valueLabelDisplay="auto"></Slider>
            <FormLabel>
                Lifts
            </FormLabel>
            <Checkbox checked={checkLift} label='Set Number of Lifts Range' onChange={(e) => {
                setCheckLift(e.target.checked);
            }}/>
            {
                checkLift ?
                    <Slider getAriaLabel={() => 'Number of Lifts Range'}
                            min={1}
                            max={100}
                            value={liftRange}
                            onChange={(e, new_value) => {
                                setLiftRange(new_value);
                            }}
                            valueLabelDisplay="auto"></Slider> : null
            }
            <FormLabel>
                Trails
            </FormLabel>
            <Checkbox checked={checkTrail} label='Set Number of Trails Range' onChange={(e) => {
                setCheckTrail(e.target.checked);
            }}/>
            {
                checkTrail ? <FormLabel>Green Trails</FormLabel> : null
            }
            {
                checkTrail ?
                    <Slider getAriaLabel={() => 'Number of Green Trails Range'}
                            min={1}
                            max={100}
                            value={greenTrailRange}
                            onChange={(e, new_value) => {
                                setGreenTrailRange(new_value);
                            }}
                            valueLabelDisplay="auto"></Slider> : null
            }
            {
                checkTrail ? <FormLabel>Blue Trails</FormLabel> : null
            }
            {
                checkTrail ?
                    <Slider getAriaLabel={() => 'Number of Blue Trails Range'}
                            min={1}
                            max={100}
                            value={blueTrailRange}
                            onChange={(e, new_value) => {
                                setBlueTrailRange(new_value);
                            }}
                            valueLabelDisplay="auto"></Slider> : null
            }
            {
                checkTrail ? <FormLabel>Black Trails</FormLabel> : null
            }
            {
                checkTrail ?
                    <Slider getAriaLabel={() => 'Number of Black Trails Range'}
                            min={1}
                            max={100}
                            value={blackTrailRange}
                            onChange={(e, new_value) => {
                                setBlackTrailRange(new_value);
                            }}
                            valueLabelDisplay="auto"></Slider> : null
            }
            <FormLabel>
                Other Filters
            </FormLabel>
            <Box>

                {
                    Object.keys(miscFilters).map((item, index) => {
                        const filterKey = item;
                        const selected = miscFilters[filterKey];
                        return (
                            <ListItem key={item}>
                                <AspectRatio
                                    variant={selected ? 'solid' : 'outlined'}
                                    color={selected ? 'primary' : 'neutral'}
                                    ratio={1}
                                    sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}
                                >
                                    <div>{selected && <Done fontSize="md"/>}</div>
                                </AspectRatio>
                                <Checkbox
                                    size="sm"
                                    color="neutral"
                                    disableIcon
                                    overlay
                                    label={filterNames[index]}
                                    variant="outlined"
                                    checked={selected}
                                    onChange={(event) =>
                                        setMiscFilters((prev) => {
                                            if (!event.target.checked) {
                                                return {...prev, [filterKey]: false};
                                            } else {
                                                return {...prev, [filterKey]: true};
                                            }
                                        })
                                    }
                                    slotProps={{
                                        action: {
                                            sx: {
                                                '&:hover': {
                                                    bgcolor: 'transparent',
                                                },
                                            },
                                        },
                                    }}
                                />
                            </ListItem>
                        );
                    })
                }
            </Box>
        </Box>
    </List>);
}